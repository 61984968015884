<template>
  <div id="dashboardVehicle" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Crew Schedule</div>
        <div class="box-S1-3 noPadding">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="hideMessage input-date"
                v-model="picker"
                label="Select Date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              type="month"
              @change="Render(picker)"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="row" style="padding: 0 20px">
      <div class="col-md-11"></div>
      <div class="col-md-1">
        <a href="https://shiftworkpilot.theprodigygroup.co.th/manageShift" onclick="window.open(this.href,'_blank');return false;">
          <button
            type="button"
            class="ColorTheme-S white--text theme-btn v-btn v-btn--flat v-btn--text theme--light v-size--default"
          >
            <span class="v-btn__content"><span>Edit</span></span>
          </button>
        </a>
      </div>
    </div>
    <div id="Dashboard" class="wrap-Main">
      <div class="box-S4">
        <div class="B-carddetail">
          <table style="width: 100%" >
            <thead>
              <tr>
                <th>Name</th>
                <th></th>

                <th v-for="item in days" :key="item">{{ item }}</th>
              </tr>
            </thead>

            <tbody id="ShiftTable">
              <tr
                v-for="shift in data"
                :key="shift.pilotDataId"
                style="text-align: center"
              >
                {{
                  IsNotSamePosition(shift)
                }}

                <!-- <td v-if="isheadder" colspan="33" style="text-align:center;">
                  {{Position[shift.pilotPositionID-1]}}
              </td> -->
                <td v-if="isdetail" style="text-align: left">
                  {{ shift.fullNameEng }}
                  <span class="small">{{ shift.pilotPosition }}</span>
                </td>
                <td v-if="isdetail">
                  {{ shift.pilotShortName }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor1 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor1 },
                  ]"
                >
                  {{ shift.day1 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor2 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor2 },
                  ]"
                >
                  {{ shift.day2 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor3 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor3 },
                  ]"
                >
                  {{ shift.day3 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor4 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor4 },
                  ]"
                >
                  {{ shift.day4 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor5 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor5 },
                  ]"
                >
                  {{ shift.day5 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor6 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor6 },
                  ]"
                >
                  {{ shift.day6 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor7 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor7 },
                  ]"
                >
                  {{ shift.day7 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor8 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor8 },
                  ]"
                >
                  {{ shift.day8 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor9 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor9 },
                  ]"
                >
                  {{ shift.day9 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor10 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor10 },
                  ]"
                >
                  {{ shift.day10 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor11 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor11 },
                  ]"
                >
                  {{ shift.day11 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor12 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor12 },
                  ]"
                >
                  {{ shift.day12 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor13 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor13 },
                  ]"
                >
                  {{ shift.day13 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor14 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor14 },
                  ]"
                >
                  {{ shift.day14 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor15 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor15 },
                  ]"
                >
                  {{ shift.day15 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor16 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor16 },
                  ]"
                >
                  {{ shift.day16 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor17 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor17 },
                  ]"
                >
                  {{ shift.day17 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor18 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor18 },
                  ]"
                >
                  {{ shift.day18 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor19 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor19 },
                  ]"
                >
                  {{ shift.day19 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor20 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor20 },
                  ]"
                >
                  {{ shift.day20 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor21 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor21 },
                  ]"
                >
                  {{ shift.day21 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor22 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor22 },
                  ]"
                >
                  {{ shift.day22 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor23 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor23 },
                  ]"
                >
                  {{ shift.day23 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor24 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor24 },
                  ]"
                >
                  {{ shift.day24 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor25 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor25 },
                  ]"
                >
                  {{ shift.day25 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor26 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor26 },
                  ]"
                >
                  {{ shift.day26 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor27 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor27 },
                  ]"
                >
                  {{ shift.day27 }}
                </td>
                <td
                  v-if="isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor28 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor28 },
                  ]"
                >
                  {{ shift.day28 }}
                </td>
                <td
                  style="color: black"
                  :style="[
                    shift.bgColor29 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor29 },
                  ]"
                  v-if="countdayinmonth >= 29 && isdetail"
                >
                  {{ shift.day29 }}
                </td>
                <td
                  style="color: black"
                  :style="[
                    shift.bgColor30 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor30 },
                  ]"
                  v-if="countdayinmonth >= 30 && isdetail"
                >
                  {{ shift.day30 }}
                </td>
                <td
                  v-if="countdayinmonth >= 31 && isdetail"
                  style="color: black"
                  :style="[
                    shift.bgColor31 == null
                      ? { backgroundColor: '#ffffff' }
                      : { backgroundColor: shift.bgColor31 },
                  ]"
                >
                  {{ shift.day31 }}
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr
                v-for="summary in summary"
                :key="summary.pilotDataId"
                style="text-align: center"
              >
                <td colspan="2">
                  {{ summary.title }}
                </td>
                <td>
                  {{ summary.day1 }}
                </td>
                <td>
                  {{ summary.day2 }}
                </td>
                <td>
                  {{ summary.day3 }}
                </td>
                <td>
                  {{ summary.day4 }}
                </td>
                <td>
                  {{ summary.day5 }}
                </td>
                <td>
                  {{ summary.day6 }}
                </td>
                <td>
                  {{ summary.day7 }}
                </td>
                <td>
                  {{ summary.day8 }}
                </td>
                <td>
                  {{ summary.day9 }}
                </td>
                <td>
                  {{ summary.day10 }}
                </td>
                <td>
                  {{ summary.day11 }}
                </td>
                <td>
                  {{ summary.day12 }}
                </td>
                <td>
                  {{ summary.day13 }}
                </td>
                <td>
                  {{ summary.day14 }}
                </td>
                <td>
                  {{ summary.day15 }}
                </td>
                <td>
                  {{ summary.day16 }}
                </td>
                <td>
                  {{ summary.day17 }}
                </td>
                <td>
                  {{ summary.day18 }}
                </td>
                <td>
                  {{ summary.day19 }}
                </td>
                <td>
                  {{ summary.day20 }}
                </td>
                <td>
                  {{ summary.day21 }}
                </td>
                <td>
                  {{ summary.day22 }}
                </td>
                <td>
                  {{ summary.day23 }}
                </td>
                <td>
                  {{ summary.day24 }}
                </td>
                <td>
                  {{ summary.day25 }}
                </td>
                <td>
                  {{ summary.day26 }}
                </td>
                <td>
                  {{ summary.day27 }}
                </td>
                <td>
                  {{ summary.day28 }}
                </td>
                <td v-if="countdayinmonth >= 29">
                  {{ summary.day29 }}
                </td>
                <td v-if="countdayinmonth >= 30">
                  {{ summary.day30 }}
                </td>
                <td v-if="countdayinmonth >= 31">
                  {{ summary.day31 }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientVan from "../plugins/feathers-client";

export default {
  data: () => ({
    days: [],
    dateSelect: new Date().toISOString().substr(0, 7),
    picker: {},
    data: [],
    summary: [],
    Position: ["Management", "Captain", "Copilot"],
    checkcase: 0,
    isheadder: true,
    isdetail: true,
    countdayinmonth: 0,
    count: 0,
    shift: [],
  }),
  components: {},
  async mounted() {
    this.picker = this.dateSelect;
    await this.ReloadData();

    $("#ShiftTable").sortable();
    $("#ShiftTable").disableSelection();
  },
  methods: {
    async Render(Select) {
      await this.ReloadData();
    },
    async ReloadData() {
      var year = this.picker.substring(0, 4);
      var month = this.picker.substring(5, 7);
      let shiftData = await this.$ProdigyFlyService.GetPilotShift(month, year);

      this.data = shiftData.data.data;
      this.summary = shiftData.data.summary;
      this.countdayinmonth = this.DaysInMonth(month, year);
      this.days = Array.from({ length: this.countdayinmonth }, (_, i) => i + 1);
      this.PositionId = 0;
    },
    DaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    IsNotSamePosition(idposition) {
      // if(idposition.pilotPositionID != this.checkcase)
      // {
      //           this.checkcase = idposition.pilotPositionID;
      //  this.isheadder= true;
      //  this.isdetail=false;
      // }
      // else
      // {
      //   this.isheadder= false;
      //   this.isdetail=true;
      // }
    },
    myFunction() {},
  },
};
</script>

<style>
.profile {
  width: 100%;
  flex: auto;
}
tbody tr:hover {
  background-color: #555555;
cursor: move; 
cursor: grab;
cursor: -moz-grab;
cursor: -webkit-grab;
}

span.small {
  font-size: smaller;
  padding-left: 5px;
  color: #cccccc;
}
</style>