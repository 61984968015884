var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Content-Page",attrs:{"id":"dashboardVehicle"}},[_c('div',{staticClass:"wrap-Main",attrs:{"id":"namePage"}},[_c('div',{staticClass:"box-S4 flex-between"},[_c('div',{staticClass:"N-Page T-size-36"},[_vm._v("Crew Schedule")]),_c('div',{staticClass:"box-S1-3 noPadding"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"hideMessage input-date",attrs:{"label":"Select Date","readonly":""},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"change":function($event){return _vm.Render(_vm.picker)},"input":function($event){_vm.menu = false}},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1)],1)])]),_vm._m(0),_c('div',{staticClass:"wrap-Main",attrs:{"id":"Dashboard"}},[_c('div',{staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th'),_vm._l((_vm.days),function(item){return _c('th',{key:item},[_vm._v(_vm._s(item))])})],2)]),_c('tbody',{attrs:{"id":"ShiftTable"}},_vm._l((_vm.data),function(shift){return _c('tr',{key:shift.pilotDataId,staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.IsNotSamePosition(shift))+" "),(_vm.isdetail)?_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(shift.fullNameEng)+" "),_c('span',{staticClass:"small"},[_vm._v(_vm._s(shift.pilotPosition))])]):_vm._e(),(_vm.isdetail)?_c('td',[_vm._v(" "+_vm._s(shift.pilotShortName)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor1 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor1 } ])},[_vm._v(" "+_vm._s(shift.day1)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor2 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor2 } ])},[_vm._v(" "+_vm._s(shift.day2)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor3 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor3 } ])},[_vm._v(" "+_vm._s(shift.day3)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor4 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor4 } ])},[_vm._v(" "+_vm._s(shift.day4)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor5 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor5 } ])},[_vm._v(" "+_vm._s(shift.day5)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor6 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor6 } ])},[_vm._v(" "+_vm._s(shift.day6)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor7 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor7 } ])},[_vm._v(" "+_vm._s(shift.day7)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor8 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor8 } ])},[_vm._v(" "+_vm._s(shift.day8)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor9 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor9 } ])},[_vm._v(" "+_vm._s(shift.day9)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor10 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor10 } ])},[_vm._v(" "+_vm._s(shift.day10)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor11 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor11 } ])},[_vm._v(" "+_vm._s(shift.day11)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor12 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor12 } ])},[_vm._v(" "+_vm._s(shift.day12)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor13 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor13 } ])},[_vm._v(" "+_vm._s(shift.day13)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor14 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor14 } ])},[_vm._v(" "+_vm._s(shift.day14)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor15 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor15 } ])},[_vm._v(" "+_vm._s(shift.day15)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor16 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor16 } ])},[_vm._v(" "+_vm._s(shift.day16)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor17 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor17 } ])},[_vm._v(" "+_vm._s(shift.day17)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor18 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor18 } ])},[_vm._v(" "+_vm._s(shift.day18)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor19 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor19 } ])},[_vm._v(" "+_vm._s(shift.day19)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor20 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor20 } ])},[_vm._v(" "+_vm._s(shift.day20)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor21 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor21 } ])},[_vm._v(" "+_vm._s(shift.day21)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor22 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor22 } ])},[_vm._v(" "+_vm._s(shift.day22)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor23 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor23 } ])},[_vm._v(" "+_vm._s(shift.day23)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor24 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor24 } ])},[_vm._v(" "+_vm._s(shift.day24)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor25 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor25 } ])},[_vm._v(" "+_vm._s(shift.day25)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor26 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor26 } ])},[_vm._v(" "+_vm._s(shift.day26)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor27 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor27 } ])},[_vm._v(" "+_vm._s(shift.day27)+" ")]):_vm._e(),(_vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor28 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor28 } ])},[_vm._v(" "+_vm._s(shift.day28)+" ")]):_vm._e(),(_vm.countdayinmonth >= 29 && _vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor29 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor29 } ])},[_vm._v(" "+_vm._s(shift.day29)+" ")]):_vm._e(),(_vm.countdayinmonth >= 30 && _vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor30 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor30 } ])},[_vm._v(" "+_vm._s(shift.day30)+" ")]):_vm._e(),(_vm.countdayinmonth >= 31 && _vm.isdetail)?_c('td',{staticStyle:{"color":"black"},style:([
                  shift.bgColor31 == null
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: shift.bgColor31 } ])},[_vm._v(" "+_vm._s(shift.day31)+" ")]):_vm._e()])}),0),_c('tfoot',_vm._l((_vm.summary),function(summary){return _c('tr',{key:summary.pilotDataId,staticStyle:{"text-align":"center"}},[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(summary.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day1)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day2)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day3)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day4)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day5)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day6)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day7)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day8)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day9)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day10)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day11)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day12)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day13)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day14)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day15)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day16)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day17)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day18)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day19)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day20)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day21)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day22)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day23)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day24)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day25)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day26)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day27)+" ")]),_c('td',[_vm._v(" "+_vm._s(summary.day28)+" ")]),(_vm.countdayinmonth >= 29)?_c('td',[_vm._v(" "+_vm._s(summary.day29)+" ")]):_vm._e(),(_vm.countdayinmonth >= 30)?_c('td',[_vm._v(" "+_vm._s(summary.day30)+" ")]):_vm._e(),(_vm.countdayinmonth >= 31)?_c('td',[_vm._v(" "+_vm._s(summary.day31)+" ")]):_vm._e()])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"col-md-11"}),_c('div',{staticClass:"col-md-1"},[_c('a',{attrs:{"href":"https://shiftworkpilot.theprodigygroup.co.th/manageShift","onclick":"window.open(this.href,'_blank');return false;"}},[_c('button',{staticClass:"ColorTheme-S white--text theme-btn v-btn v-btn--flat v-btn--text theme--light v-size--default",attrs:{"type":"button"}},[_c('span',{staticClass:"v-btn__content"},[_c('span',[_vm._v("Edit")])])])])])])}]

export { render, staticRenderFns }